@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat/static/Montserrat-Regular.ttf);
}

@tailwind utilities;

/* @layer base {
  html {
    font-family: Proxima Nova, system-ui, sans-serif;
  }
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .modal {
  transition: opacity 0.25s ease;
}
.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip {
  position: absolute;
  text-align: center;
  padding: 6px;
  font: 12px sans-serif;
  background: #4A9BD5;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  z-index: 50;
}

/* .calenderTooltip {
  position: absolute;			
  text-align: center;		
  background: #4A9BD5;
  border: 0px;	
  border-radius: 8px;	
  font-size: 12px;		
  pointer-events: none;		
  white-space: normal;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
} */

.calenderTooltip {
  @apply absolute text-center bg-[red] border-0 rounded-xl text-xs pointer-events-none whitespace-normal px-1 font-bold
}

.calenderTooltipParentContainer {
  @apply flex flex-wrap -m-4 absolute pointer-events-none shadow-md border-2 border-primary rounded-lg bg-primary
}

.calenderTooltipContainer {
  @apply p-4
}

.tooltipContainer {
  @apply p-4 space-y-2
}

.tooltipStatus {
  @apply text-white text-base font-bold p-1 rounded-md border-2 border-[#E8E8E8]
}